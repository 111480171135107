/* You can add global styles to this file, and also import other style files */
@import "@progress/kendo-theme-bootstrap/dist/all.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');


  
html,
body,
my-app {
    padding: 0;
    margin: 0;
    /* font-family:Verdana, Geneva, Tahoma, sans-serif; */
    font-family: 'Roboto', sans-serif;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    /* background-color: #eedacd; */
        background: #fff;
} 

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid darkgray;
    border-radius: 5px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;
    box-sizing: border-box;
    flex: 1 0 calc(33% - 20px); /* This will take up to 1/3 of the container's width */
}

.card-title {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.card:hover {
    background-color: #f0f0f0;
}

@media (max-width: 500px) {
    .card {
        flex-basis: 100%;
    }
}

.breadcrumb {
    font-size: 14px;
    font-weight: bold;
    /* border-bottom: solid 1px lightgray; */
    padding-bottom: 10px;    
}
.breadcrumb-no-border {
    border-bottom: 0;
}

.grid-column-header {
    background-color: rgb(153,179,172);
    font-size: 20pt;
    font-weight: bold;
}

.header-banner {
    background: #28533F;
    width: 100%;
    height: 190px;
    min-height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
}

.header-banner:before {
    position: absolute;
    content: '';
    background: url('./assets/curve_shape.svg')no-repeat top right;
    right: 0;
    width: 100%;
    bottom: 0;
    background-size: contain;
        height: 150px;
}

.header-banner h2 {
    color: #FFF;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    margin: 0;
    position: relative;
    z-index: 10;
}
.k-grid .k-grid-header {
    padding: 0px;
}
.k-grid .k-grid-header-wrap .k-grid-header-table .k-table-thead .k-table-row .k-table-th .k-cell-inner .k-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.k-grid .k-grid-header-wrap .k-grid-header-table .k-table-thead .k-table-row .k-table-th .k-cell-inner .k-link .k-sort-icon {
    color: #fff;
}
.k-grid .k-grid-header-wrap .k-grid-header-table .k-table-thead .k-table-row .k-table-th {
    background: #28533F;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 12px;
    border-right: 1px solid #E9EDEB;

}

.k-grid .k-grid-container .k-grid-table-wrap .k-grid-table .k-table-tbody .k-table-row .k-table-td {
    color: #424242;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 12px;
    border-right: 1px solid #E9EDEB;

}

.k-grid .k-grid-container .k-grid-table-wrap .k-grid-table .k-table-tbody .k-table-alt-row,
.k-grid .k-grid-container .k-grid-table-wrap .k-grid-table .k-table-tbody .k-table-alt-row .k-grid-content-sticky,
.k-grid .k-grid-container .k-grid-table-wrap .k-grid-table .k-table-tbody .k-table-alt-row:hover .k-grid-content-sticky {
    background: #F6F7F7;
    border-right: 1px solid #E9EDEB;
}

.k-grid .k-grid-container .k-grid-content {
    overflow: auto;
}

.k-grid .k-pager .k-pager-numbers-wrap .k-selected {
    background: #28533F !important;
    color: #fff !important;
}

.k-grid .k-pager .k-pager-numbers-wrap .k-button {
    color: #28533F;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.container-large {
    max-width: 1240px;
    margin: 0 auto;
    box-sizing: border-box;
}
.container-large .home-img {
    width:100%
}

.main-content {
    flex: 1;
    overflow: auto;
}

.breadcrumbWrapper {
    border-bottom: 1px solid #e9edeb;
    background: #e9edeb;
    position: relative;
    z-index: 1;
    padding-left: 250px; /* Same as the width of the sidebar */
}

.breadcrumb, .breadcrumb a {
    padding: 0;
    font-size: 14px;
    color: #28533F;
    font-weight: 400;
    line-height: 48px;
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
}

.breadcrumb a.active {
    color: var(--Secondary-Green, #6fc067);
    cursor: pointer;

}

.breadcrumb .k-icon {
    color: #28533f;
}
.back-sec {
    display: none;
}

/* filter design css */
.filter-wrapper {
    border-radius: 25px;
    border: 1px solid #E9EDEB;
    background: #FFF;
    padding: 20px 25px; 
    width: 320px;
    box-sizing: border-box;
    min-width: 265px;
    position: relative;
}
.filter-wrapper .title span {
    position: absolute;
    width: 25px;
    min-width: 20px;
    height: 25px;
    border-radius: 50%;
    color: var(--component-text-white, #FFF);
    text-align: center;
    font-size: 11.333px;
    font-weight: 600;
    line-height: 10px;
    top: -10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
}
.filter-wrapper .configuration-section-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}
.filter-wrapper .configuration-section-text .title {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #424242;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.drop-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #E9EDEB;
}
.drop-menu label {
    color: #424242;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; 
    margin-bottom: 10px;
    width: 100%;
}
.filter-tax-years {
    margin-bottom: 25px;
    margin-left: 10px;
}

.tab-main-wrap {
    padding: 50px 0;
}

.editorial-main-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}
.editorial-main-wrapper .role-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.editorial-main-wrapper .role-title h3 {
    color: var(--Primary-Green, #28533F);
    font-size: 28px;
    font-weight: 300;
    line-height: 40px;
    margin: 0;
}
.editorial-main-wrapper .role-title .k-button-group .k-button {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    outline: none;
    box-shadow: none;
}
.editorial-main-wrapper .role-title .k-button-group .k-button.k-selected {
    background: var(--Primary-Green, #28533F);
    outline: none;
    box-shadow: none;
}
.layout-wrapper.filter-open {
    padding: 25px;
    position: absolute;
    width: calc(100% - 325px);
    transition: left 0.5s;
    left: 325px;
    top:105px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 1px solid #E9EDEB;
    background: #FFF;
}
.layout-wrapper.filter-close {
    padding: 25px;
    position: absolute;
    width: calc(100%);
    transition: left 0.5s;
    left: 0;
    top:105px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 1px solid #E9EDEB;
    background: #FFF;
}
::ng-deep .filter-tax-years-slider .k-slider-track-wrapper .k-slider-items {
display: none;
}
.filter-tax-years-slider .k-slider-track .k-slider-selection {
    background: #6FC067;
}
::ng-deep  .filter-tax-years-slider .k-slider-track-wrapper .k-slider-track .k-draghandle {
    border-color: #6FC067;
    color: white;
    background-color: #6FC067;
}

.drop-menu .filter-item {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.drop-menu .filter-item .k-button {
    border-radius: 4px !important;
    border: 1px solid #A9BAB2;
    padding: 4px 9px;
    background: #fff;
    color: #A9BAB2;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.drop-menu .filter-item .k-button.k-selected {
    border-radius: 4px;
    border: 1px solid #6FC067;
    background: #6FC067;
    color: var(--component-text-white, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.k-slider .k-draghandle {
    border-color: #6FC067 !important;
    color: white;
    background-color: #6FC067 !important;
}
.header-wrapper-role {
    display: flex;
    gap: 50px;
    align-items: flex-start;
}
.mobile-filter-btn {
    display: none;
}
@media (max-width: 767px) {
    .mobile-filter-btn {
        display: flex;
    }
    .layout-wrapper.filter-open, .layout-wrapper.filter-close {
        position: unset;
        width: 100%;
    }
    .editorial-main-wrapper .role-title {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .tab-main-wrap .editorial-main-wrapper .role-title h3 {
        order: 1;
        font-size: 18px !important;
        font-weight: 300 !important;
        line-height: 30px !important;
        padding: 20px 0 15px;
    }
    .mob-layout-wrapper {
        display: flex;
        order: 0;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .mob-layout-wrapper .mobile-filter-btn {
        border-radius: 25px;
        border: 1px solid #E9EDEB;
        background: #FFF;
        position: relative;
        padding: 10px 20px;
    }
    .mobile-filter-btn .title {
        color: #424242;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px; 
        display: flex;
        gap: 10px;
        text-transform: capitalize;
        align-items: center;
    }
    .mobile-filter-btn .title span {
        position: absolute;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        color: var(--component-text-white, #FFF);
        text-align: center;
        font-size: 8.333px;
        font-weight: 600;
        line-height: 10px; 
        top: -10px; 
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: red;
     }
    .tab-main-wrap {
        padding: 25px 16px !important;
    }
    .filter-wrapper {
        display: none;
    }
    .header-banner {
        height: 150px;
        min-height: 150px;
        background: #28533F;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .breadcrumb {
        padding: 0 16px;
        display: none;
    }

    .header-banner h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }

    .header-banner:before {
        height: 130px;
        background-size: contain;
    }
    .back-sec {
        padding: 0 16px;
      display: flex;
      gap: 10px;
      align-items: center;
      
    }
    .back-sec a {
         color: #424242;
             font-size: 14px;
             font-weight: 400;
             line-height: 42px;
        text-decoration: none;
      }
}

.k-window-titlebar-actions [title="Minimize"] {
    display: none !important;
}
.kendo-window-hide-restore .k-window-titlebar-actions [title="Restore"] {
    display: none !important;
}
.k-loader.k-loader-primary {
    left:50% !important;
}
.gg-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    flex-direction: column;
    margin-top: 30px;
}
.gg-loader img {
    width: 20%;
}

@media screen and (min-width: 1401px) {
    .container-large {
        max-width: 1320px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .gg-loader img {
        width: 20%;
    }
}
@media screen and (max-width: 1199px) {
    .container-large {
        max-width: 960px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .gg-loader img {
        width: 30%;
    }
}
@media screen and (max-width: 991px) {
    .container-large {
        max-width: 720px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .gg-loader img {
        width: 30%;
    }
}

@media screen and (max-width: 767px) {
    .container-large {
        max-width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .gg-loader img {
        width: 35%;
    }
}

